import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ApiService from "./common/api/api.service";
import AuthService from "./common/auth.service";
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import titleMixin from "./mixins/titleMixin";
import authMixin from "./mixins/authMixin";
import Config from "./common/config";
import * as Sentry from '@sentry/vue';
import VueCookies from 'vue-cookies';
import AlertService from "./common/alert.service";
import './registerServiceWorker';
import {TabulatorFull} from "tabulator-tables";
import {get} from "@/client/utilities/Object"
import MetricsService from "@/common/metrics.service";
import VueAxios from "vue-axios";
import axios from "axios";
import idleTracker from "@/common/idle-tracker";
import {LogService} from "@/common/log";
import dayjs from "dayjs";

idleTracker.start();

Sentry.init({
    Vue,
    dsn: "https://105d0c51fc8f296c5a8a9dd06fb4d6dd@o4507704014012416.ingest.us.sentry.io/4507704015978496",
    integrations: [
        Sentry.browserTracingIntegration({router}),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["vw.legac-group.com", /^\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    enabled: process.env.NODE_ENV === 'production',
    logErrors: true
});

Vue.config.productionTip = false

Vue.use(VueAxios, axios);
Vue.use(Toast, Config.toastOptions);
Vue.use(VueCookies);

TabulatorFull.extendModule('validate', 'validators', {
    partQuantityMax: (cell, value, parameters) => {

        if (!value || value.length === 0) {
            return true;
        }

        value = parseFloat(value);
        let part_quantity = parseFloat(get(cell.getData(), 'part_quantity', 0));

        if (value <= 0) {
            return true;
        }

        return (value <= part_quantity);
    }
});

const MainLog = new LogService('App');

AlertService.loadMessages();

ApiService.init();

Vue.axios.interceptors.request.use(request => {
    const headers = MetricsService.getHeaders();

    for (const header in headers) {
        const value = headers[header];
        request.headers.set(header, value);
    }

    return request;
});

const customParseFormat = require('dayjs/plugin/customParseFormat')
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

Vue.filter('date', (value, format) => {

    if (!format) {
        format = 'MM/DD/YY hh:mma';
    }
    let date = null;

    try {
        date = dayjs.utc(value, 'YYYY-MM-DD HH:mma').local();
    } catch (e) {
    }

    if (!date || !date.isValid()) {
        date = dayjs.utc(value, 'YYYY-MM-DD hh:mm:ss').local();
    }


    if (date.isValid()) {
        return date.format(format);
    }

    return value;
});

router.beforeEach((to, from, next) => {
    MetricsService.getGeoLocation();
    MetricsService.updateMetrics();
    next();
});

Vue.mixin(authMixin);
Vue.mixin(titleMixin);

const app = new Vue({
    store,
    router,
    vuetify,
    el: '#app',
    render: h => h(App),
    created() {
        AlertService.displayToasts();
        const user = AuthService.getUser();

        if (user) {
            Sentry.setUser({
                email: user.email,
                name: user.name
            });
        }
    },
});

Vue.axios.interceptors.response.use(response => {
    AlertService.processResponseAlerts(response);
    return response;
}, error => {

    AlertService.processResponseAlerts(error.response);

    if (error.response) {
        if (error.response.status === 401) {

            Vue.$toast.error('You must be logged in to access this page.');

            AuthService.clearSession();

            if (router.currentRoute.name !== 'Login') {
                router.push({name: 'Login'});
            }

        } else if (error.response.status === 419) {
            //CSRF token mismatch, reload page
            Vue.$toast.error('CSRF token mismatch, please reload page.');
        }
    } else {
        MainLog.error('Error in http response', error);
    }

    return Promise.reject(error);
});